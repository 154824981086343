import packageJson from '../../package.json';

export const environment = {
    version: packageJson.version,
    production: true,
    rollbar: {
        accessToken: 'cd9cc3b8d52447159e20a00181e9d52e',
        environment: 'live',
        enabled: true
    },
    hmr: false,
    DOMAIN_NAME: 'https://tradext.gr',
    API_URL: 'https://api.tradext.gr',
    NODE_API: 'http://127.0.0.1:5000',
    WS_URL: 'ws://localhost:5001/',
    testing: false
};
